import React, { useEffect } from "react";
import { motion } from "framer-motion";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import CookieConsent, { Cookies } from "react-cookie-consent";
import GlobalStyle from "../components/globalstyle";
import Maxwidth from '../components/maxwidth'
import Nav from '../components/nav'
import Footer from '../components/footer'
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

export default function Layout(props) {

  const { children, path } = props;

  const page_type = props.pageContext.pagetype;

  const location = useLocation();

  if (typeof window !== `undefined`) {
    initializeAndTrack(location)
  }

//   useEffect(() => {
//     CookieConsent.run({
//       guiOptions: {
//           consentModal: {
//               layout: "box",
//               position: "bottom left",
//               equalWeightButtons: true,
//               flipButtons: false
//           },
//           preferencesModal: {
//               layout: "box",
//               position: "right",
//               equalWeightButtons: true,
//               flipButtons: false
//           }
//       },
//       categories: {
//           necessary: {
//               readOnly: true
//           },
//           analytics: {}
//       },
//       language: {
//           default: "en",
//           autoDetect: "browser",
//           translations: {
//               en: {
//                   consentModal: {
//                       title: "This website uses cookies",
//                       description: "We use cookies to improve your experience and to analyse our traffic. We do not share information about your use of our site with any third parties other than for the use of analysing our site traffic.",
//                       acceptAllBtn: "Accept all",
//                       acceptNecessaryBtn: "Reject all",
//                       showPreferencesBtn: "Manage preferences",
//                       footer: "<a href=\"/privacy-policy.pdf\">Privacy Policy</a>\n<a href=\"/terms\">Terms and conditions</a>"
//                   },
//                   preferencesModal: {
//                       title: "Consent Preferences Center",
//                       acceptAllBtn: "Accept all",
//                       acceptNecessaryBtn: "Reject all",
//                       savePreferencesBtn: "Save preferences",
//                       closeIconLabel: "Close modal",
//                       serviceCounterLabel: "Service|Services",
//                       sections: [
//                           {
//                               title: "Strictly Necessary Cookies <span class=\"pm__badge\">Always Enabled</span>",
//                               linkedCategory: "necessary"
//                           },
//                           {
//                               title: "Analytics Cookies",
//                               linkedCategory: "analytics"
//                           },
//                       ]
//                   }
//               }
//           }
//       }
//   });
// }, []);



  if (path === '/') {

    return (
      <React.Fragment>
        <GlobalStyle />
        <motion.div
          key={path}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}>
          {children}
        </motion.div>
      </React.Fragment>
    );

  } else {

    return (
      <React.Fragment>
        <GlobalStyle />
        <Nav />
        <motion.div
          key={path}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}>
          {page_type !== 'page' &&
            <Maxwidth path={path}>{children}</Maxwidth>
          }
          {page_type === 'page' &&
            <>{children}</>
          }
        </motion.div>
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          enableDeclineButton
          onDecline={() => {
            console.log('declined')
          }}
          cookieName="gatsby-gdpr-google-tagmanager"
          style={{ background: "#ffc200" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
      </React.Fragment>
    );

  }
}
