import React from "react";
import styled from "styled-components";
import { navigate, useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";
import X from "../../images/x.svg";

const Menu = styled.nav`
  background-color: var(--pink);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 230px;
  transition: all 0.25s ease-in-out;
  opacity: 0;
  display: flex;
  flex-direction: column;
  padding-right: 50px;
  padding-top: 25px;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: -1;
  transform: translateX(230px);
  &.open {
    opacity: 1;
    z-index: 10000;
    transform: translateX(0px);
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  &.closed {
    right: 230px;
    z-index: -1;
    width: 0;
    overflow-x: hidden;
  }
  img {
    width: 48px;
    margin-bottom: 1rem;
    transform: translateX(12px);
    cursor: pointer;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0 0 0 50px;
    width: 100%;
    li {
      font-family: "Catamaran", sans-serif;
      font-size: 24px;
      text-align: right;
      font-weight: 300;
      padding-bottom: 0.75rem;
      a {
        color: #565858;
        font-family: "Catamaran", sans-serif;
        text-decoration: none;
        &.active {
          font-weight: 600;
        }
        &:hover {
          font-weight: 500;
        }
      }
    }
  }
`;

const PopoverMenu = ({ all_page_links, menuVisible, setMenuVisible }) => {
  const location = useLocation();

  const { pathname } = location;

  const data = useStaticQuery(graphql`
    query {
      allSanityArtist {
        edges {
          node {
            surname
            first_name
          }
        }
      }
      allSanityMenu {
        edges {
          node {
            links {
              title
              url
            }
          }
        }
      }
    }
  `);

  const menu_links = data.allSanityMenu.edges[0].node.links;

  const handleClick = (e, url) => {
    e.preventDefault();
    setMenuVisible(false);
    navigate(url);
  };

  return (
    <Menu className={menuVisible ? "open menu" : "closed menu"}>
      <div onClick={() => setMenuVisible(false)}>
        <img src={X} alt="close" />
      </div>
      <ul>
        {menu_links.map((m, index) => {
          const cn = m.url.replace("/", "");

          if (m.url === "/" && pathname === "/") {
            return (
              <li key={index}>
                <a
                  className="active"
                  href="#"
                  onClick={(e) => handleClick(e, m.url)}
                >
                  {m.title}
                </a>
              </li>
            );
          } else if (
            all_page_links.includes(m.url) ||
            all_page_links.includes(m.url + "/")
          ) {

              return (
                <li key={index}>
                  <a
                    className={
                      pathname.startsWith(m.url) && m.url !== "/"
                        ? `active ${cn}`
                        : cn
                    }
                    href="#"
                    onClick={(e) => handleClick(e, m.url)}
                  >
                    {m.title}
                  </a>
                </li>
              );

          } else {
            return <></>;
          }
        })}
      </ul>
    </Menu>
  );
};

export default PopoverMenu;
