import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link, navigate } from "gatsby";
import { slugify, artistname } from "../../lib/utils";
import Maxwidth from "../maxwidth";
import Logo from "../../images/cobj.gif";
import PopoverMenu from "../popover_menu";
import Facebook from "../../images/facebook.svg";
import Twitter from "../../images/x-logo.svg";
import Instagram from "../../images/instagram.svg";
import Youtube from "../../images/youtube.svg";
import Burger from "../../images/burger.svg";
import LinkedIn from "../../images/linkedin.svg";
import X from "../../images/x.svg";

const Tagline = styled.span`
  display: inline-block;
  padding-left: 1rem;
  @media (max-width: 1330px) {
    padding-left: 0;
  }
`;

const Container = styled.nav`
  max-width: calc(1400px - 2rem);
  margin-left: auto;
  margin-right: auto;
  min-height: 106px;
  display: flex;
  border-bottom: 1px solid #fbd1e4;
  @media (max-width: 1036px) {
    max-width: 100%;
  }
  @media (max-width: 880px) {
    display: none;
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  padding-left: 45px;
  span {
    color: var(--darkgrey);
    font: italic normal normal 17px/21px "Playfair Display";
  }
  img {
    width: 82px;
  }
  @media (max-width: 1340px) {
    padding-left: 0;
    img {
      width: 60px;
      margin-right: 1rem;
    }
  }
`;

const Right = styled.div`
  display: flex;
  flex: 1 0;
  align-items: center;
  justify-content: flex-end;
  padding-right: 45px;
  img {
    transform: scaleX(-1) translateY(5px);
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  li {
    display: inline-block;
    margin-left: 24px;
    &:first-of-type {
      margin-left: 0;
    }
    a {
      text-decoration: none;
      font-size: 16px;
      color: var(--darkgrey);
      font-family: "Catamaran", sans-serif;
      transition: all 0.25s ease-in-out;
      &:hover {
        color: var(--pink);
      }
      &.active {
        color: #ffc100;
        font-weight: 600;
      }
    }
  }
  @media (max-width: 970px) {
    padding-left: 1rem;
    padding-right: 0;
    li {
      margin-left: 1rem;
    }
  }
`;

const ContainerMobile = styled.div`
  display: none;
  @media (max-width: 880px) {
    display: flex;
    padding-top: 0.5rem;
    flex-direction: column;
    width: 100%;
  }
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  justify-content: space-between;
  span {
    font-size: 12px;
    color: var(--text);
    font-style: italic;
    opacity: 0.85;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 50px;
    margin-right: 1rem;
    margin-left: 1rem;
  }
`;

const BurgerContainer = styled.div`
  margin-right: 1rem;
`;

const Submenu = styled.div`
  background-color: var(--pink);
  color: #585656;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: ${(props) => (props.search ? "9px" : "0.8rem")};
  padding-bottom: ${(props) => (props.search ? "8px" : "0.8rem")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    filter: invert(100%);
  }
`;

const SearchInputMobile = styled.div`
  border: none;
  position: relative;
  span {
    font-family: Catamaran, sans-serif;
    position: absolute;
    left: 0;
    top: 2px;
    left: 8px;
    transition: all 0.25s ease-in-out;
    &.visible {
      opacity: 1;
    }
    &.hidden {
      opacity: 0;
    }
  }
  input {
    background-color: var(--pink);
    outline: 0;
    border: none;
    font-size: 16px;
    margin: 0;
    color: #fff;
    font-family: Catamaran, sans-serif;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #fff;
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: #fff;
      opacity: 1;
    }
  }
`;

const Logos = styled.div`
  display: flex;
  column-gap: 0.8rem;
  padding-top: 2px;
  padding-bottom: 1px;
  img {
    height: 18px;
  }
`;

const Nav = () => {
  const [menuVisible, setMenuVisible] = useState(false);

  const isBrowser = typeof window !== "undefined";

  const data = useStaticQuery(graphql`
    query {
      allSitePage {
        edges {
          node {
            path
          }
        }
      }
      allSanityMenu {
        edges {
          node {
            links {
              title
              url
            }
          }
        }
      }
      allSanityArtist {
        edges {
          node {
            surname
            first_name
          }
        }
      }
      allSanityPerson {
        edges {
          node {
            cobj_staff
            title
            email
          }
        }
      }
    }
  `);

  const menu_links = data.allSanityMenu.edges[0].node.links;

  const all_page_links = data.allSitePage.edges.map((p) => p.node.path);

  return (
    <div className="no-print">
      <PopoverMenu
        all_page_links={all_page_links}
        menuVisible={menuVisible}
        setMenuVisible={setMenuVisible}
      />

      <ContainerMobile>
        <Menu>
          <LogoContainer>
            <Link activeClassName="active" to="/">
              <img src={Logo} alt="COBJ" />
            </Link>
            <Tagline>KBJ & PBJ Management Corporate Division</Tagline>
          </LogoContainer>
          <BurgerContainer>
            <img src={Burger} alt="" onClick={() => setMenuVisible(true)} />
          </BurgerContainer>
        </Menu>
        <Submenu>
          <Logos>
            <a href="https://www.linkedin.com/in/cobj-management-a2ab28270/?originalSubdomain=uk">
              <img className="linkedin" src={LinkedIn} alt="LinkedIn" />
            </a>

            <a href="https://www.instagram.com/cobjmanagement">
              <img className="instagram" src={Instagram} alt="Instagram" />
            </a>

            <a href="https://x.com/cobjmanagement">
              <img className="twitter" src={Twitter} alt="Twitter" />
            </a>
          </Logos>
        </Submenu>
      </ContainerMobile>

      <Maxwidth nominheight>
        <Container>
          <Left>
            <Link activeClassName="active" to="/">
              <img src={Logo} alt="PBJ" />
            </Link>
            <Tagline>KBJ & PBJ Management Corporate Division</Tagline>
          </Left>
          <Right>
            <ul>
              {menu_links.map((m, index) => {
                const cn = m.url.replace("/", "");

                return (
                  <li key={index}>
                    <Link className={cn} activeClassName="active" to={m.url}>
                      {m.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Right>
        </Container>
      </Maxwidth>
    </div>
  );
};

export default Nav;
